import React from 'react'
import { graphql } from 'gatsby'

import Disorder from '../components/Disorders/Disorders'
import Layout from '../components/layout'
import SEO from '../components/seo'

const DisordersPage = ({ data }) => (
  <Layout>
    <SEO
      title="Stoornissen"
      keywords={[
        `Logopediepraktijk`,
        `Mireille Peters`,
        `Zeewolde`,
        `Harderwijk`,
        `Ermelo`,
      ]}
    />
    <h2 className="disorders__wrapper__header">Stoornissen</h2>
    <div className="disorders__wrapper container-fluid">
      <div className="row">
        {data.stoornissen.edges.map(stoornis => (
          <div
            key={stoornis.node.id}
            className="disorder__card col-xs-12 col-lg-6 col-xl-4"
          >
            <Disorder stoornis={stoornis.node} />
          </div>
        ))}
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    stoornissen: allContentfulStoornissen {
      edges {
        node {
          id
          titel
          korteOmschrijving {
            korteOmschrijving
          }
          uitleg {
            uitleg
          }
          link
        }
      }
    }
  }
`
export default DisordersPage
