import React, { Component } from 'react'

export default class Disorders extends Component {
  state = {
    showMoreInfo: false,
    css: 'hidden',
    btnText: 'Lees meer',
  }
  toggleReadMore = () => {
    this.state.showMoreInfo
      ? this.setState({
          showMoreInfo: false,
          css: 'hidden',
          btnText: 'Lees meer',
        })
      : this.setState({
          showMoreInfo: true,
          css: 'visible',
          btnText: 'Lees minder',
        })
  }

  render() {
    const { stoornis } = this.props
    return (
      <div className="disorders">
        <h3 className="disorders__header">{stoornis.titel}</h3>
        <p>{stoornis.korteOmschrijving.korteOmschrijving}</p>
        <button
          onClick={this.toggleReadMore}
          className="btn btn-outline-primary"
        >
          {this.state.btnText}
        </button>
        <div className={this.state.css}>
          {stoornis.uitleg.uitleg}
          {stoornis.link ? (
            <div className="disorder__link">
              <a
                className="text-left nav-link nav-link--alt"
                href={stoornis.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Meer info
              </a>
            </div>
          ) : (
            undefined
          )}
        </div>
      </div>
      /* <h3 className="disorder__header">{disorder.titel}</h3>
        <p>{disorder.korteOmschrijving.korteOmschrijving}</p>
        <button
          onClick={this.toggleReadMore}
          className="btn btn-outline-primary"
        >
          Lees meer
        </button>
        <div className="disorder__more-info">{disorder.uitleg.uitleg}</div>)
        undefined )}
        {disorder.link ? (
          <div className="disorder__link">
            <h6>Link</h6>
            <a
              className="nav-link nav-link--alt"
              href={disorder.link}
              targe="_blank"
            >
              Meer info
            </a>
          </div>
        ) : (
          undefined
        )}
      </div> */
    )
  }
}
